window.$ = window.jQuery = require('jquery');

$(() => {
    $('body').on('click', '#toggle-password', function(e) {
    // $('#toggle-password').on('click', (e) => {
        let $this = $(this);

        let passwordInput = $('#password');

        let eyeIcon = $this.find('.far');

        let passwordType = passwordInput.attr('type');

        if (passwordType == 'password') {
            passwordInput.attr('type', 'text');
            eyeIcon
                .removeClass('fa-eye-slash')
                .addClass('fa-eye');
        } else if (passwordType == 'text') {
            passwordInput.attr('type', 'password');
            eyeIcon
                .removeClass('fa-eye')
                .addClass('fa-eye-slash');
        }

    });
})
